import { AnimatedTextBlock } from "@components/atoms/AnimatedTextBlock"
import { BlockItem } from "@components/atoms/BlockItem"
import { BlockWrapper } from "@components/atoms/BlockWrapper"
import { Pill } from "@components/atoms/Pill"
import { defineBlock, EditableText, InnerBlocks } from "eddev/blocks"

export const meta: BlockMeta = {
  title: "Home Percentage",
}

export default defineBlock("home/percentage", (props) => {
  return (
    <BlockWrapper theme="dark" toolbarState="live-index">
      <div className="flex flex-col w-full md:flex-row">
        <BlockItem className="bg-orange h-[114vw] flex justify-center items-start md:w-[calc(50%-4px)] md:mx-1 md:h-auto md:shrink-0" innerClassName="w-full self-stretch md:h-[45vw] flex flex-col justify-between">
          <div className="flex w-full gap-4 flex-col md:gap-5">
            <Pill className="bg-orange-light">
              <EditableText
                as="span"
                className=""
                store="percentage-pill"
                inlineToolbar={false}
                defaultValue="Why this matters"
                placeholder="Why this matters"
              />
            </Pill>
            <AnimatedTextBlock textKey="title" textClassName="type-body-l" triggerStart="top bottom-=15%" triggerEnd="bottom top" placeholder="Percentage of people diagnosed with Herpes that have depressive or suicidal thoughts. (NZHF, 2024)" />
          </div>

          <EditableText
            as="div"
            className="mt-[33vw] type-title-xxl leading-none md:mt-[10.54vw]"
            store="percent"
            inlineToolbar={false}
            defaultValue="30% "
            placeholder="30% "
          />
        </BlockItem>
        <InnerBlocks
          template={[
            ["home/image-text", null],
          ]}
          templateLock="all"
          orientation="vertical"
        />
      </div>
    </BlockWrapper>
  )
})